// Form global variables
var lastSelectedAddress = '';
var lastSelectedPostCode = '';

// Form validator functions
var checkAddressSearch = function (errorMsg) {

	if ($('#basicSearch:visible, section[id^="shareBasicSearch"]:visible').length > 0) {

		if ($('#formAddress, [id^="shareFormAddress"]').val().length == 0) {

			errorMsg = errorMsg + '<li>"Adresse" er obligatorisk</li>';
		}

		if ($('input[name="postcode"], input[name^="sharePostcode"]').val().length == 0) {

			errorMsg = errorMsg + '<li>"Postnummer" er obligatorisk</li>';
		}
	}

	if ($('#container-checkbox:visible').length > 0 && $('input[name="container"]:checked').length == 0) {
		errorMsg = errorMsg + '<li>"Hvilken beholder" er obligatorisk</li>';
	}

	if ($('#alternativeSearch:visible').length > 0) {

		if ($('#kommune option:selected').val().length == 0) {

			errorMsg = errorMsg + "<li>Velg kommune</li>";
		}

		if ($('#gnr').val().length == 0) {

			errorMsg = errorMsg + '<li>"Gårdsnr" er obligatorisk</li>';
		}

		if ($('#bnr').val().length == 0) {

			errorMsg = errorMsg + '<li>"Bruksnr" er obligatorisk</li>';
		}

		if (($('#fnr').val().length > 0 && $('#snr').val().length == 0) || ($('#fnr').val().length == 0 && $('#snr').val().length > 0)) {

			errorMsg = errorMsg + '<li>"Bruksnr" krever at "Seksjonsnr" fylles ut</li>';
		}
	}

	return errorMsg;
}

var validateChangeContainer = function () {

	var errorMsg = '<ul>';
	var sizeError = false;

	if ($('.hasChildren:checked')[0] == undefined) {

		errorMsg = errorMsg + "<li>Velge en beholder</li>";
	} else {

		$('.hasChildren:checked').each(function () {

			var tmp = $(this).data('show');

			if ($(':checked', tmp)[0] == undefined) {

				if (!sizeError) {

					errorMsg = errorMsg + "<li>Velg beholderstørrelse</li>";
					sizeError = true;
				}
			}
		});
	}

	errorMsg = checkAddressSearch(errorMsg);

	if (errorMsg != '<ul>') {

		errorMsg = errorMsg + "</ul>";

		$("#errorMessage").html("<p>Vi har følgende problemer:</p>" + errorMsg);

		$("#errorMessage").show();
		$("html, body").animate({

			scrollTop: $("#errorMessage").offset().top - 20
		}, 1000);

		return false;
	} else {

		$("#errorMessage").hide();
		return true;
	}
}

var validateException = function () {

	var errorMsg = '<ul>';
	var sizeError = false;

	errorMsg = checkAddressSearch(errorMsg);

	if ($("#other:checked")[0] != undefined) {

		if ($('#description').val().length == 0) {

			errorMsg = errorMsg + "<li>Vennligst oppgi grunn for søknad</li>";
		}
	}

	if ($('#from').val().length > 0 && $('#to').val().length > 0) {

		var fromArray = $('#from').val().split('.');
		var from = new Date(moment.tz(fromArray[2] + '-' + fromArray[1] + '-' + fromArray[0] + ' 00:00:00', "Europe/Oslo").format().split("+")[0]);

		var toArray = $('#to').val().split('.');
		var to = new Date(moment.tz(toArray[2] + '-' + toArray[1] + '-' + toArray[0] + ' 00:00:00', "Europe/Oslo").format().split("+")[0]);

		if (from.getTime() >= to.getTime()) {

			errorMsg = errorMsg + "<li>Feil med unntaksperioden</li>";
		}
	}

	if (errorMsg != '<ul>') {

		errorMsg = errorMsg + "</ul>";

		$("#errorMessage").html("<p>Vi har følgende problemer:</p>" + errorMsg);

		$("#errorMessage").show();
		$("html, body").animate({

			scrollTop: $("#errorMessage").offset().top - 20
		}, 1000);

		return false;
	} else {

		$("#errorMessage").hide();
		return true;
	}
}

var validateBenefit = function () {

	var errorMsg = '<ul>';
	var sizeError = false;

	errorMsg = checkAddressSearch(errorMsg);

	if (errorMsg != '<ul>') {

		errorMsg = errorMsg + "</ul>";

		$("#errorMessage").html("<p>Vi har følgende problemer:</p>" + errorMsg);

		$("#errorMessage").show();
		$("html, body").animate({

			scrollTop: $("#errorMessage").offset().top - 20
		}, 1000);

		return false;
	} else {

		$("#errorMessage").hide();
		return true;
	}
}

var validateOrderAccess = function () {

	var errorMsg = '<ul>';
	var sizeError = false;


	if ($('.atLeastOne:checked')[0] == undefined) {

		errorMsg = errorMsg + "<li>Kryss av for årsak til bestilling</li>";
	}

	errorMsg = checkAddressSearch(errorMsg);

	if (errorMsg != '<ul>') {

		errorMsg = errorMsg + "</ul>";

		$("#errorMessage").html(errorMsg);

		$("#errorMessage").show();
		$("html, body").animate({

			scrollTop: $("#errorMessage").offset().top - 20
		}, 1000);

		return false;
	} else {

		$("#errorMessage").hide();
		return true;
	}
}

var validateCompostForm = function () {

	var errorMsg = '<ul>';
	var sizeError = false;


	if ($("input[name='hasCompost']").length && $("input[name='hasCompost']:checked").val() == undefined) {

		errorMsg = errorMsg + '<li>"Har du hjemmekompostering nå?" er obligatorisk</li>';
	}

	if (errorMsg != '<ul>') {

		errorMsg = errorMsg + "</ul>";

		$("#errorMessage").html(errorMsg);

		$("#errorMessage").show();
		$("html, body").animate({

			scrollTop: $("#errorMessage").offset().top - 20
		}, 1000);

		return false;
	} else {

		$("#errorMessage").hide();
		return true;
	}
}

var validateOrderMaterial = function () {

	var errorMsg = '<ul>';
	var sizeError = false;

	errorMsg = checkAddressSearch(errorMsg);

	if ($('.atLeastOne:checked')[0] == undefined) {

		errorMsg = errorMsg + "<li>Velge en beholder</li>";
	}

	if (errorMsg != '<ul>') {

		errorMsg = errorMsg + "</ul>";

		$("#errorMessage").html("<p>Vi har følgende problemer:</p>" + errorMsg);

		$("#errorMessage").show();
		$("html, body").animate({

			scrollTop: $("#errorMessage").offset().top - 20
		}, 1000);

		return false;
	} else {

		$("#errorMessage").hide();
		return true;
	}
}


$(document).ready(function () {

	var dataTimer = null;
	var dataAdded = false;
	var currentAddress = "";

	/* Removes extra p from accordion list */
	$('div.accordions').nextAll('p').remove();

	/* validade form input fields */
	var checkInput = function (value) {
		if (!value) {
			emptyField = true;
			return null;
		} else {
			return value;
		}
	}

	var setDisableFile = function (shouldDisable) {
		var form = $(this);
		if (form.find("input[type=file]") && form.find("input[type=file]").length > 0) {
			var file = form.find("input[type=file]")[0];

			if (file && file.files && (file.files.length == 0) && $(".formCompost").length == 0) {
				$(file).prop('disabled', shouldDisable);
			}
		}
	}

	var validateEmail = function (email) {
		if (!checkInput(email)) return null;
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (re.test(email)) return email;
		else return null;
	}

	// Digits ONLY validator
	$(".digits__only").keypress(function (e) {
		if (e.which != 0 && e.which != 8 && (e.which < 48 || e.which > 57)) {
			return false;
		}
	});

	$('#alternateCalendar').hide();

	$('#showAlternativeHours').click(function () {
		$('#alternateCalendar').show();
	});

	//Remove the red color in the field accept when the checkbox is checked
	$("#sms-varsling, #form-suggestion").find("input[name='accept']").change(function () {
		if ($(this).prop('checked')) {
			$("#sms-varsling, #form-suggestion").find("#accept").css("color", "");
			$("#sms-varsling, #form-suggestion").find("#accept .form__checkbox").css("border-color", "");
		}
	});

	//Format mask
	$(document).on("keyup", ".number-only", function () {
		var self = $(this);
		var value = self.val();
		var maxlength = self.prop("maxlength");
		value = value.replace(/[^0-9]/g, '');
		if (maxlength >= 0)
			value = value.substring(0, maxlength);
		self.val(value);
	});

	//################################################# form-extra-waste-collection - begin
	var isPermissiveSommerExtraWaste = $('[name=isPermissiveSommer]').val();

	var showPriceParagraphExtraWaste = function (show) {
		var paragraph = $('.replacePrice').parent();
		var paragraphWrapper;
		// Try to hide the correct parent
		if (paragraph && (paragraphWrapper = paragraph.closest('fieldset')) && paragraphWrapper.length > 0) {
			if (!show) {
				paragraphWrapper.addClass('hidden');
			} else {
				paragraphWrapper.removeClass('hidden');
			}
		}
		if (!show) {
			paragraph.addClass('visibility-hidden');
		} else {
			paragraph.removeClass('visibility-hidden');
		}
	};

	var replacePriceExtraWaste = function (priceText) {
		var replacePrice$ = function (selector) {
			var isText = function () {
				return this.nodeType === 3;
			};
			var isElement = function () {
				return this.nodeType === 1;
			};
			var textNodes = $(selector).contents().filter(isText);
			var elementNodes = $(selector).contents().filter(isElement);
			var replace = function () {
				var text = $(this).text() || '';
				return text.replace(/\{XX kr\}/g, '<span class="replacePrice">' + priceText + '</span>');
			};
			textNodes.replaceWith(replace);
			textNodes = elementNodes.contents().filter(isText);
			textNodes.replaceWith(replace);
		};
		$('.replacePrice').text(priceText);
		replacePrice$('.text__text');
		replacePrice$('.form__label');
		showPriceParagraphExtraWaste(true);
	};

	$('.propertyExtraWaste').on('change', function () {
		// Clear previous errors
		$("#errorMessage").html('');
		$("#errorMessage").hide();

		var self = $(this);
		var propertyNumber = self.val();
		$('[name=dealCode]').prop('checked', false); // Resetting the dealCode
		$('.hideOnInvalid').removeClass('hidden');
		$('.propertyContracts').addClass('hidden');
		var contractsContainer = $('.contracts-' + propertyNumber);
		var contractsSize = parseInt(contractsContainer.data('contractsize'));
		var contractsTagName = contractsContainer && contractsContainer.prop("tagName") && contractsContainer.prop("tagName").toLocaleLowerCase();

		if (isNaN(contractsSize) || contractsSize == 0) {
			replacePriceExtraWaste('');
			showPriceParagraphExtraWaste(false);
		} else {
			if (contractsSize > 1) {
				contractsContainer.removeClass('hidden');
			}
			var first$ = $('.p' + propertyNumber).first();
			first$.prop('checked', true).change();
		}
		if (contractsTagName == 'div') {
			contractsContainer.removeClass('hidden');
			$('.hideOnInvalid').addClass('hidden');
			replacePriceExtraWaste('');
			showPriceParagraphExtraWaste(false);
		}
	});
	setTimeout(function () {
		var first$ = $('.propertyExtraWaste').first();
		first$.prop('checked', true).change();
	});


	$('.contractExtraWaste').on('change', function () {
		$('.hideOnInvalid').removeClass('hidden');
		var self = $(this);
		var contractType = self.data('dealtype');
		var standardPrice = $('[name=standardPrice]').val();
		var largeFamilyPrice = $('[name=largeFamilyPrice]').val();
		var groupPrice = $('[name=groupPrice]').val();
		var priceText;
		switch (contractType) {
			case 'samarbeid':
				priceText = groupPrice;
				break;
			case 'family':
				priceText = largeFamilyPrice;
				break;
			case 'standard':
				priceText = standardPrice;
				break;
			default:
				replacePriceExtraWaste('');
				showPriceParagraphExtraWaste(false);
				$('.hideOnInvalid').addClass('hidden');
				return;
		}
		replacePriceExtraWaste(priceText);
	});
	//################################################# form-extra-waste-collection - end

	//################################################# form-gardenwaste - begin
	var isPermissiveSommerGardenWaste = $('[name=isPermissiveSommer]').val();

	var showPriceParagraphGardenWaste = function (show) {
		var paragraph = $('.replacePrice').parent();
		var paragraphWrapper;
		// Try to hide the correct parent
		if (paragraph && (paragraphWrapper = paragraph.closest('fieldset')) && paragraphWrapper.length > 0) {
			if (!show) {
				paragraphWrapper.addClass('hidden');
			} else {
				paragraphWrapper.removeClass('hidden');
			}
		}
		if (!show) {
			paragraph.addClass('visibility-hidden');
		} else {
			paragraph.removeClass('visibility-hidden');
		}
	};

	var replacePriceGardenWaste = function (priceText) {
		var replacePrice$ = function (selector) {
			var isText = function () {
				return this.nodeType === 3;
			};
			var isElement = function () {
				return this.nodeType === 1;
			};
			var textNodes = $(selector).contents().filter(isText);
			var elementNodes = $(selector).contents().filter(isElement);
			var replace = function () {
				var text = $(this).text() || '';
				return text.replace(/\{XX kr\}/g, '<span class="replacePrice">' + priceText + '</span>');
			};
			textNodes.replaceWith(replace);
			textNodes = elementNodes.contents().filter(isText);
			textNodes.replaceWith(replace);
		};
		$('.replacePrice').text(priceText);
		replacePrice$('.text__text');
		replacePrice$('.form__label');
		showPriceParagraphGardenWaste(true);
	};

	$('.propertyGardenWaste').on('change', function () {
		// Clear previous errors
		$("#errorMessage").html('');
		$("#errorMessage").hide();

		var self = $(this);
		var propertyNumber = self.val();
		$('[name=dealCode]').prop('checked', false); // Resetting the dealCode
		$('.hideOnInvalid').removeClass('hidden');
		$('.propertyContracts').addClass('hidden');
		var contractsContainer = $('.contracts-' + propertyNumber);
		var contractsSize = parseInt(contractsContainer.data('contractsize'));
		var contractsTagName = contractsContainer && contractsContainer.prop("tagName") && contractsContainer.prop("tagName").toLocaleLowerCase();

		if (isNaN(contractsSize) || contractsSize == 0) {
			replacePriceGardenWaste('');
			showPriceParagraphGardenWaste(false);
		} else {
			if (contractsSize > 1) {
				contractsContainer.removeClass('hidden');
			}
			var first$ = $('.p' + propertyNumber).first();
			first$.prop('checked', true).change();
		}
		if (contractsTagName == 'div') {
			contractsContainer.removeClass('hidden');
			$('.hideOnInvalid').addClass('hidden');
			replacePriceGardenWaste('');
			showPriceParagraphGardenWaste(false);
		}
	});
	setTimeout(function () {
		var first$ = $('.propertyGardenWaste').first();
		first$.prop('checked', true).change();
	});


	$('.contractGardenWaste').on('change', function () {
		$('.hideOnInvalid').removeClass('hidden');
		var self = $(this);
		var contractType = self.data('dealtype');
		var standardPrice = $('[name=standardPrice]').val();
		var largeFamilyPrice = $('[name=largeFamilyPrice]').val();
		var groupPrice = $('[name=groupPrice]').val();
		var priceText;
		switch (contractType) {
			case 'samarbeid':
				priceText = groupPrice;
				break;
			case 'family':
				priceText = largeFamilyPrice;
				break;
			case 'standard':
				priceText = standardPrice;
				break;
			default:
				replacePriceGardenWaste('');
				showPriceParagraphGardenWaste(false);
				$('.hideOnInvalid').addClass('hidden');
				return;
		}
		replacePriceGardenWaste(priceText);
	});
	//################################################# form-gardenwaste - end

	/* Form submit */
	$('.formService').on('submit', function (e) {

		e.preventDefault();


		var showError = function (errorMsg) {
			if (errorMsg) {
				$("#errorMessage").html("<p>" + (typeof errorMsg === 'string' ? errorMsg : errorMsg.msg) + "</p>");
			}

			$(".loading").remove();
			$(".form-block").remove();
			$("#errorMessage").show();
			$("html, body").animate({
				scrollTop: $("#errorMessage").offset().top - 20
			}, 1000);
		};

		// #################################################### form-extra-waste-collection - start

		if ($(this).hasClass("formExtraWasteCollection")) {
			try {
				// Convert form into plain object
				var formObj = $(this).serializeArray().reduce(function (obj, item, index) {
					if (obj[item.name]) {
						if (!Array.isArray(obj[item.name])) {
							// Convert it to array since there's already a value with this name
							obj[item.name] = [obj[item.name]];
						}
						obj[item.name].push(item.value);
					} else {
						obj[item.name] = item.value;
					}
					return obj;
				}, {});

				if (lastSelectedAddress && lastSelectedAddress.trim() !== formObj.address.trim()) {
					return showError("Vennligst velg en adresse som finnes i nedtrekkslisten.")
				}

				if (lastSelectedPostCode && lastSelectedPostCode.trim() !== formObj.postcode.trim()) {
					return showError("Vennligst velg en adresse som finnes i nedtrekkslisten.");
				}

				var shouldValidateWasteCollectionCheckboxes = false;
				if (isPermissiveSommerExtraWaste !== undefined) {
					shouldValidateWasteCollectionCheckboxes = formObj.dealCode || !isPermissiveSommerExtraWaste;
				}

				if (shouldValidateWasteCollectionCheckboxes) {

					var extraWasteTotalCheckBoxes = $(this).find("input.checkbox-form-extra-waste-collection");
					var extraWasteTotalChecked = $(this).find("input.checkbox-form-extra-waste-collection:checked");
					var extraWasteError = false;

					if (extraWasteTotalCheckBoxes && (extraWasteTotalCheckBoxes.length > 0)) {

						if (extraWasteTotalChecked) {
							if (extraWasteTotalChecked.length != extraWasteTotalCheckBoxes.length) {
								extraWasteError = true;
							}
						} else {
							extraWasteError = true;
						}
					}

					if (extraWasteError) {

						$("#errorMessage").html("<p>Bekreftelsene må være avkrysset.</p>");

						$(".loading").remove();
						$(".form-block").remove();
						$("#errorMessage").show();
						$("html, body").animate({

							// scrollTop: $("#errorMessage").offset().top - 20
							scrollTop: 0
						}, 1000);

						return false;
					}
				}
			} catch (e) {
				console.error(e);
			}
		}

		//################################################# form-extra-waste-collection - end

		// #################################################### form-gardenwaste - start

		if ($(this).hasClass("formGardenWaste")) {
			try {
				// Convert form into plain object
				var formObj = $(this).serializeArray().reduce(function (obj, item, index) {
					if (obj[item.name]) {
						if (!Array.isArray(obj[item.name])) {
							// Convert it to array since there's already a value with this name
							obj[item.name] = [obj[item.name]];
						}
						obj[item.name].push(item.value);
					} else {
						obj[item.name] = item.value;
					}
					return obj;
				}, {});

				if (lastSelectedAddress && lastSelectedAddress.trim() !== formObj.address.trim()) {
					return showError("Vennligst velg en adresse som finnes i nedtrekkslisten.")
				}

				if (lastSelectedPostCode && lastSelectedPostCode.trim() !== formObj.postcode.trim()) {
					return showError("Vennligst velg en adresse som finnes i nedtrekkslisten.");
				}

				var shouldValidateWasteCollectionCheckboxes = false;
				if (isPermissiveSommerGardenWaste !== undefined) {
					shouldValidateWasteCollectionCheckboxes = formObj.dealCode || !isPermissiveSommerGardenWaste;
				}

				if (shouldValidateWasteCollectionCheckboxes) {

					var gardenWasteTotalCheckBoxes = $(this).find("input.checkbox-form-gardenwaste");
					var gardenWasteTotalChecked = $(this).find("input.checkbox-form-gardenwaste:checked");
					var gardenWasteError = false;

					if (gardenWasteTotalCheckBoxes && (gardenWasteTotalCheckBoxes.length > 0)) {

						if (gardenWasteTotalChecked) {
							if (gardenWasteTotalChecked.length != gardenWasteTotalCheckBoxes.length) {
								gardenWasteError = true;
							}
						} else {
							gardenWasteError = true;
						}
					}

					if (gardenWasteError) {

						$("#errorMessage").html("<p>Bekreftelsene må være avkrysset.</p>");

						$(".loading").remove();
						$(".form-block").remove();
						$("#errorMessage").show();
						$("html, body").animate({

							// scrollTop: $("#errorMessage").offset().top - 20
							scrollTop: 0
						}, 1000);

						return false;
					}
				}
			} catch (e) {
				console.error(e);
			}
		}

		//################################################# form-gardenwaste - end

		var form = $(this);

		var formData = new FormData(form[0]);

		// Accept terms verification for the sms-varsling form
		if (form.attr("id") == "sms-varsling") {
			var accept = form.find("input[name='accept']").prop('checked');
			if (!accept) {
				form.find("#accept").css("color", "#ce4703");
				form.find("#accept .form__checkbox").css("border-color", "#ce4703");
				return false;
			} else {
				form.find("#accept").css("color", "");
				form.find("#accept .form__checkbox").css("border-color", "");
			}
		}

		if (form.data('validate') != undefined) {

			if (typeof window[form.data('validate')] === 'function') {

				//Create the function
				var fn = window[form.data('validate')];

				//Call the function
				var validated = fn();
			} else {
				var validated = true
			}
		} else {
			var validated = true
		}

		if (validated) {
			setDisableFile(true);

			// Clear previous errors
			// $("#errorMessage").html('');
			$("#errorMessage").hide();

			// Throw the form values to the server!
			$.ajax({
				type: form.attr('method'),
				url: form.attr('action'),
				data: formData,
				async: true,
				beforeSend: function () {

					form.append('<a class="form-block" />');
					$('input[type=submit]').after('<div class="loading"></div>');
				},
				success: function (data) {

					if (data.status == 'fail') {
						setDisableFile(false);
						showError(data.msg)

					} else if (data.status == 'success') {

						if ($("#confirmationPage").length) {

							window.location.href = $("#confirmationPage").val();
						} else {

							if (data.msg) {

								$("#successMessage").html(data.msg);
							}

							$("#errorMessage").hide();
							$("#successMessage").show();
							$(".hideField").hide();
							$(".hideIntro").hide();

							$("html, body").animate({

								scrollTop: 0
								// scrollTop: $("#successMessage").offset().top - 20
							}, 1000);

						}
					}
				},
				error: function (jqXHR) {
					var erroMsg = jqXHR.responseJSON || jqXHR.responseText;
					setDisableFile(false);
					showError(erroMsg);
				},
				cache: false,
				contentType: false,
				processData: false
			});
		}

		return false;
	});

	$('.select2-custom').select2({
		minimumResultsForSearch: Infinity
	});

	function setCookie (cname, cvalue, exdays) {

		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + "; " + expires;
	}

	function getCookie (cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function resetList () {

		/* RESET li list */
		$('#navigationList, [id^="shareNavigationList"]').find('.navigationlist__item').remove();
		$('#resultList').find('.navigationlist__item').remove();
		$('#jsResults').find('#hentedag').remove();
	}

	function getData () {

		$('.js-suggestions').hide();

		var $url = "_/service/com.enonic.app.rfd/addressLookup?address=" + encodeURI($('#address').val());

		resetList();

		$.get($url, function (data) { // on sucess

			for (var key in data.addresses) {
				$('#navigationList').append('<li class="navigationlist__item"> <a href="#" class="navigationlist__link  navigationlist__link--arrow  js-click" data-show=".js-results" data-hide=".js-suggestions" data-street="' + data.addresses[key].GateNavn + '" data-street-code="' + data.addresses[key].GateId + '" data-house-number="' + data.addresses[key].AdresseHusNummer + '" data-region-id="' + data.addresses[key].KommuneNummer + '" data-postcode="' + data.addresses[key].PostNummer + '">' + data.addresses[key].Text + '</a></li>');
			}

			$('.js-suggestions').show();
		})
			.fail(function () {
				console.log("fail");
			});
	}

	function getFormData (selector) {
		var $address = '';

		if ($('#navigationList').length) {
			$('.js-suggestions').hide();
			$address = $('#formAddress').val()
		} else if (selector.parent().parent().find(".js-suggestions").length) {
			selector.parent().parent().find(".js-suggestions").hide()
			$address = selector.val()
		}

		var poststed = $('#poststedReplace').val()
		var urlPoststed = (poststed == 'true') ? '&poststedReplace=1' : ''
		var $url = "_/service/com.enonic.app.rfd/addressLookup?address=" + encodeURI($('#formAddress').val()) + "&source=pickup" + urlPoststed;
		if ($('input[name="changeOption"]').length) {
			$url = $url + "&specialRegion=1"
		}

		resetList();

		var strList = ''
		$.get($url, function (data) { // on sucess

			for (var key in data.addresses) {
				if ($('#navigationList').length) {
					strList = $("#navigationList").text();
					if (!strList.includes(data.addresses[key].Text)) {
						$('#navigationList').append('<li class="navigationlist__item"> <a href="#formAddress" class="navigationlist__link  navigationlist__link--arrow  js-form-click" data-show=".js-results" data-hide=".js-suggestions" data-street="' + data.addresses[key].GateNavn + '" data-street-code="' + data.addresses[key].GateId + '" data-house-number="' + data.addresses[key].AdresseHusNummer + '" data-region-id="' + data.addresses[key].KommuneNummer + '" data-postcode="' + data.addresses[key].PostNummer + '" data-poststed="' + data.addresses[key].PostStedCapitalized + '">' + data.addresses[key].Text + '</a></li>');
					}
				} else if (selector.parent().parent().find("ul.navigationlist").length) {
					strList = selector.parent().parent().find("ul.navigationlist").text();
					if (!strList.includes(data.addresses[key].Text)) {
						selector.parent().parent().find("ul.navigationlist").append('<li class="navigationlist__item"> <a href="#formAddress" class="navigationlist__link  navigationlist__link--arrow  js-form-click" data-show=".js-results" data-hide=".js-suggestions" data-street="' + data.addresses[key].GateNavn + '" data-street-code="' + data.addresses[key].GateId + '" data-house-number="' + data.addresses[key].AdresseHusNummer + '" data-region-id="' + data.addresses[key].KommuneNummer + '" data-postcode="' + data.addresses[key].PostNummer + '" data-poststed="' + data.addresses[key].PostStedCapitalized + '">' + data.addresses[key].Text + '</a></li>');
					}
				}
			}

			if ($('#navigationList').length) {
				$('.js-suggestions').show();
			} else if (selector.parent().parent().find(".js-suggestions").length) {
				selector.parent().parent().find(".js-suggestions").show()
			}

		})
			.fail(function () {
				console.log("fail");
			});
	}

	function setCity (postCode) {
		var $url = "_/service/com.enonic.app.rfd/getCity?postnummer=" + postCode;

		$.get($url, function (data) {
			if (data) {
				if (data.result && data.result.length) {
					$('input[name="city"]').val(data.result);
					$('input[name="city"]').trigger("change");
				} else {
					$('input[name="city"]').attr("readonly", false);
				}
			}
		})
			.fail(function () {
				console.log("failed to get the city");
			});
	}

	$(function () {

		/*\
		$("#address").keyup(function(e){

			if(getCookie('address') != $('#address').val() ){

				if(e.which == 13) {
					return;
				}

				var val = $(this).val().trim();

				if (!currentAddress || currentAddress != val){
					currentAddress = val;

					if(val.length >= 3){ // checks if there are enough characters in input length before calling the service
						dataAdded = true;

						if (dataTimer != null)
							clearTimeout(dataTimer);
						dataTimer = setTimeout(getData, 500);
					}
					if(val.length < 3){

						dataAdded = false;
						resetList();
					}
				}
			}
		});
		*/

		$('#formAddress, [id^="shareFormAddress"]').keyup(function (e) {

			if (getCookie('address') != $(this).val()) {

				if (e.which == 13) {
					return;
				}

				var val = $(this).val().trim();

				if (!currentAddress || currentAddress != val) {
					currentAddress = val;

					if (val.length >= 3) { // checks if there are enough characters in input length before calling the service
						dataAdded = true;

						if (dataTimer != null)
							clearTimeout(dataTimer);
						dataTimer = setTimeout(getFormData($(this)), 500);
					}
					if (val.length < 3) {

						dataAdded = false;
						resetList();
					}
				}
			}
		});

		$('#displayAddresses').click(function (e) {

			e.preventDefault();
			e.stopPropagation();

			if (getCookie('address') != $('#address').val()) {

				if ($('#address').val().length == 0) {
					dataAdded = false;
					$('#address').focus();
				}

				if ($('#address').val().length > 0) {
					if (dataTimer != null)
						clearTimeout(dataTimer);

					getData();
				}
			} else {
				$('#address').focus();
			}

			return false;
		});

		$('.hasChildren').change(function () {
			if (!$(this).prop('checked')) {
				var tmp = $(this).data('show');

				if ($(tmp).hasClass('indent40')) {
					$(tmp).addClass('hidden');
				} else {
					$(tmp).hide();
				}

			}
		})

		$('.chooseProperty').change(function () {

			if ($(this).prop('checked')) {
				$('#property').val($(this).val());

				// var valueSelector = "input[value='Hus skal rives / er revet']";
				var valueSelector = "input[value='Boenheten(e) skal stå ubebodd, velg årsak:']"; // RFD-450

				if ($(this).val() == "Fritidsbolig/hytte") {
					valueSelector = "input[value='Skal kondemneres/er kondemnert']";
				}

				if ($(valueSelector) &&
					($(valueSelector).length > 0)) {

					$(valueSelector).prop("checked", true);
				}

			}
		})
	});

	function getFraction (id) {

		if (id === 1) {
			return "Restavfall";
		} else if (id === 2) {
			return "Papir- og plastemballasje"; // Papir
		} else if (id === 3) {
			return "Matavfall"; // Våtorganisk
		} else if (id === 4) {
			return "Glass- og metallemballasje"; // Glass/Metallemballasje
		} else if (id === 5) {
			return "Drikkekartonger";
		} else if (id === 6) {
			return "Spesialavfall";
		} else if (id === 7) {
			return "Plast";
		} else if (id === 8) {
			return "Trevirke";
		} else if (id === 9) {
			return "Tekstiler";
		} else if (id === 10) {
			return "Hageavfall";
		} else if (id === 11) {
			return "Metaller";
		} else if (id === 12) {
			return "Hvitevarer/EE-avfall";
		} else if (id === 13) {
			return "Papp";
		} else if (id === 14) {
			return "Møbler";
		} else {
			return "";
		}
	}

	function getPickupDays ($url) {

		$.get($url, function (data) { // on sucess

			$('#jsResults').append('<p id="hentedag">Vil du ha beskjed når det er hentedag? <a href="' + data.appUrl + '">Få varsel på app</a> eller <a href="' + data.smsUrl + '">SMS</a>.</p>');

			var days = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
			var months = ['Januar', 'februar', 'Mars', 'april', 'mai', 'juni', 'July', 'august', 'september', 'oktober', 'november', 'desember'];

			data.fetchDays.forEach(function (fetchDay) {

				var fractionIDs = getFraction(fetchDay.fraksjonId);

				var firstDay = new Date(fetchDay.tommedatoer[0]);
				var dayOne = days[firstDay.getDay()] + " " + firstDay.getDate() + ". " + months[firstDay.getMonth()];

				if (typeof fetchDay.tommedatoer[1] !== 'undefined') {
					var secondDay = new Date(fetchDay.tommedatoer[1]);
					var dayTwo = days[secondDay.getDay()] + " " + secondDay.getDate() + ". " + months[secondDay.getMonth()];
				}

				$('#resultList').append('<li class="navigationlist__item  navigationlist__item&#45;&#45;no-link  navigationlist__item&#45;&#45;title">' + fractionIDs + '</li>');
				$('#resultList').append('<li class="navigationlist__item  navigationlist__item&#45;&#45;no-link">' + dayOne + '</li>');
				if (typeof fetchDay.tommedatoer[1] !== 'undefined') {
					$('#resultList').append('<li class="navigationlist__item  navigationlist__item&#45;&#45;no-link">' + dayTwo + '</li>');
				}

			});
		})
			.fail(function () {
				console.log("fail to  fetch data");
			});
	}

	$(document).on('click', '.navigationlist__link.js-click', function () {

		$('#address').val($(this).text()); // set the selected value from the result to input 'adresse'
		//setCookie('address', $(this).text(), 60); // set cookie to last 48h

		//setCookie('street', encodeURI($(this).data('street')), 60);
		//setCookie('street-code', $(this).data('street-code'), 60);
		//setCookie('house-number', encodeURI($(this).data('house-number')), 60);
		//setCookie('region-id', encodeURI($(this).data('region-id')), 60);
		//setCookie('postcode', $(this).data('postcode'), 60);

		resetList();

		var $url = "_/service/com.enonic.app.rfd/pickupDays?street=" + encodeURI($(this).data('street')) + '&street_code=' + $(this).data('street-code') + '&house_number=' + encodeURI($(this).data('house-number')) + '&region_id=' + encodeURI($(this).data('region-id'));

		getPickupDays($url);

	});

	$(document).on('click', '.navigationlist__link.js-form-click', function () {

		var parentDiv = $('.navigationlist__link.js-form-click').parent().parent().parent().parent();

		if ($('#formAddress').length) {
			var poststed = $('#poststedReplace').val()
			if (poststed == 'true') {
				var textField = $(this).text();
				textField = textField.replace($(this).data('poststed'), '')
				lastSelectedAddress = textField.replace(',', '') || '';

				$('#formAddress').val(textField.replace(',', ''));  // set the selected value from the result to input 'adresse'
			} else {
				$('#formAddress').val($(this).text());
			}

			$('#formAddressFull').val($(this).text());

			lastSelectedPostCode = $(this).data('postcode').toString() || '';

			$('input[name="postcode"]').val($(this).data('postcode')); // append cookie value to postcode input
			$('input[name="postcode"]').trigger("change");
		} else {
			if (parentDiv.length && $('[id^="shareFormAddress"]').length) {
				parentDiv.find('[id^="shareFormAddress"]').val($(this).text())
			}
		}

		if ($('input[name="postcode"]').length) {
			$('input[name="postcode"]').val($(this).data('postcode')); // append cookie value to postcode input
			$('input[name="postcode"]').trigger("change");
		} else {
			if (parentDiv.length && $('input[name^="sharePostcode"]').length) {
				parentDiv.find('input[name^="sharePostcode"]').val($(this).data('postcode'));
				parentDiv.find('input[name^="sharePostcode"]').trigger("change");
			}
		}

		setCity($(this).data('postcode'));

		//setCookie('address', $(this).text(), 60); // set cookie to last 48h
		//setCookie('postcode', $(this).data('postcode'), 60);

		resetList();

		return false;
	});

	// ----- Status på mine saker --------------------------------
	var selectedLogProperty = $("input[type='radio'].changeLogInput:checked");

	if (selectedLogProperty) {
		var selectedLogPropertyId = selectedLogProperty.attr("id");
		$('table#change-log-table-' + selectedLogPropertyId).css("display", "table");

		var noLogsTable = (!$('table#change-log-table-' + selectedLogPropertyId).length);

		if (noLogsTable) {
			$("p#noLogsWarning").css("display", "block");
		}
	}

	$(document).on('click', '.changeLogInput', function (e) {
		$("p#noLogsWarning").css("display", "none");
		var id = $(this).attr('id');
		$('table.change-log-table').css("display", "none");
		$('table#change-log-table-' + id).css("display", "table");

		var noLogsTable = (!$('table#change-log-table-' + id).length);

		if (noLogsTable) {
			$("p#noLogsWarning").css("display", "block");
		}
	})
	// -----------------------------------------------------------

	if (document.cookie) {

		var cookieAddress = getCookie('address');
		var cookiePostcode = getCookie('postcode');
		var street = getCookie('street');
		var streetCode = getCookie('street-code');
		var houseNumber = getCookie('house-number');
		var regionId = getCookie('region-id');

		if (cookieAddress) {

			//$( 'input[name="address"]' ).val( cookieAddress ); // append cookie value to address input
		}

		if (cookiePostcode) {

			//$( 'input[name="postcode"]' ).val( cookiePostcode ); // append cookie value to postcode input
			//$( 'input[name="postcode"]' ).trigger("change");
		}

		if (street && streetCode && houseNumber && regionId) {

			//var $url = "_/service/com.enonic.app.rfd/pickupDays?street="+ street +'&street_code='+ streetCode +'&house_number='+ houseNumber +'&region_id='+ regionId;

			//getPickupDays( $url );
		}
	}

	$('a.alternate-search').click(function (e) {

		e.preventDefault();
		e.stopPropagation();

		resetList();

		$('input[name="address"]').val('');
		$('input[name="postcode"], input[name^="sharePostcode"]').val('');
		$('.form__location.js-location').html('');
		$('input[name="gnr"]').val('');
		$('input[name="bnr"]').val('');
		$('input[name="fnr"]').val('');
		$('input[name="snr"]').val('');

		$('#basicSearch, [id^="shareBasicSearch"]').hide();
		$('#alternativeSearch').show();
	});

	$('a.standard-search').click(function (e) {

		e.preventDefault();
		e.stopPropagation();

		resetList();

		$('input[name="address"]').val('');
		$('input[name="postcode"], input[name^="sharePostcode"]').val('');
		$('.form__location.js-location').html('');
		$('input[name="gnr"]').val('');
		$('input[name="bnr"]').val('');
		$('input[name="fnr"]').val('');
		$('input[name="snr"]').val('');


		var cookieAddress = getCookie('address');
		if (cookieAddress) {

			//$( 'input[name="address"]' ).val( cookieAddress ); // append cookie value to address input
		}

		$('#alternativeSearch').hide();
		$('#basicSearch, [id^="shareBasicSearch"]').show();
	});

	$('#finn-hentedager').on('submit', function (e) {
		e.preventDefault();

		var form = $(this);
		var formData = new FormData(form[0]);

		if (formData.get('kommune') && formData.get('gnr') && formData.get('bnr')) {
			var eiendomString = formData.get('gnr') + "/" + formData.get('bnr');

			if (formData.get('fnr')) {
				if (!formData.get('snr')) $('#snr').focus();
				else eiendomString = eiendomString + "/" + formData.get('fnr') + "/" + formData.get('snr');
			}

			resetList();

			var $url = "_/service/com.enonic.app.rfd/pickupDays?region_id=" + formData.get('kommune') + "&eiendomString=" + eiendomString;

			getPickupDays($url);
		} else $('#kommune').focus();
	});

	$('#meld-feil').on("click", ".js-change", function (e) {
		if ($(this).data("field")) {
			var newMessageId = "#" + $(this).data("field");
			$("#successMessage").text($(newMessageId).text());
		}

		if ($(this).data("check-containers")) {
			//Uncheck all containers in
			if ($('input[name="container"]:checked').length > 0) {
				$('input[name="container"]').prop('checked', false);
			}

			if ($('input[name="container"]:checked').length == 0) {
				$(".js-details").addClass("hidden");
				$(".js-details").hide();
			}
		}

		/*This code is to turn the file input required or not when button 'Skade på beholder' is clicked*/
		if ($(this).data("addrequire")) {
			var requireId = "#" + $(this).data("addrequire");
			$(requireId).prop('required', true);
		}

		if ($(this).data("removerequire")) {
			var requireId = "#" + $(this).data("removerequire");
			$(requireId).removeAttr('required')
		}


		/*This code is to show or hide the file input when button 'Skade på beholder' is clicked*/
		if ($(this).data("show-field")) {
			var requireId = "." + $(this).data("show-field");
			$(requireId).removeClass("hidden");
			$(requireId).show();
		}
		if ($(this).data("hide-field")) {
			var requireId = "." + $(this).data("hide-field");
			$(requireId).addClass("hidden");
			$(requireId).hide();
		}
	});

	$('.formService').on('click', 'input[name="addressCode"]', function (e) {
		$("#changeContainerAddressType").val($("input[name='addressCode']:checked").data("address-type"));

		if ($(this).data("propertynumber")) {
			var newContract = "#" + $(this).data("propertynumber");
			$('#contractNumber').attr("value", $(newContract).val());
			$("#property").attr("value", $(newContract).data("adaddresstype"));
			$("#postCode").attr("value", $(newContract).data("postcode"));

			if ($('input[name="contract"]').length > 0) {
				$('input[name="contract"]').attr("value", $(newContract).val());
			}
		}
		if ($(this).data("label")) {
			$("#addressInput").val($(this).data("label"));
		}
	});

	//Add attachment input in grant forms
	$(".add-file").on("click", function (e) {
		e.preventDefault();
		var count = $("#poser-sekker, #meld-feil").find("input[type=file]").length;
		var _f = $('.form__label.attachment');
		_f.append($('<input type="file" class="form__file" name="receipt_' + count + '" required="required" />'));
	});

	if ($('input[name="hideClass"]').val()) {
		var classToHide = $('input[name="hideClass"]').val();
		$(classToHide).addClass("hidden");
	}
	if ($('input[name="showClass"]').val()) {
		var classToShow = $('input[name="showClass"]').val();
		$(classToShow).removeClass("hidden");
	}

	$("#meld-feil").on("click", 'input[name="smsRemind"]', function (e) {
		if ($("#errorMessage").has("ul").length) {
			if ($("#errorMessage").hasClass("hidden")) {
				$("#errorMessage").removeClass("hidden");
				$("#errorMessage").show();
			} else {
				$("#errorMessage").addClass("hidden");
				$("#errorMessage").hide();
			}

			$("html, body").animate({
				scrollTop: 0
			}, 1000);
		}
	});


	$("[name='container']").on("change", function () {

		if (!$("[name='container']:checked").val()) {
			$(".js-details").hide();
		} else {
			$(".js-details").show();
		}

		var checkedOptions = [];
		$("[name='container']:checked").each(function () {
			checkedOptions.push($(this).val());
		});

		if (checkedOptions.indexOf("Todelt beholder for mat- og restavfall") != -1) {
			$("[name='damage'][value='1074']").parent().show();
			$("[name='damage'][value='1075']").parent().show();
		} else {
			$("[name='damage'][value='1074']").parent().hide();
			$("[name='damage'][value='1075']").parent().hide();
		}
	});

	// ##### invoices list page #####################################################

	var contractNumber = "";
	var addressCode = "";

	var selectedRadioElement = $("input[type='radio'].properties-radio-button:checked");

	if (selectedRadioElement) {
		$(".no-invoices-warning").css("display", "none");
		var selectedRadioElementId = selectedRadioElement.attr("id");
		var firstRadioAddressCode = selectedRadioElement.attr("data-address-code");

		if (firstRadioAddressCode) {
			addressCode = firstRadioAddressCode;
		}

		var invalidProperty = String(selectedRadioElement.attr("data-invalid-property")) == "true";

		if (invalidProperty) {
			$(".invoicesCategoryHeading").css("display", "none");
			$("#notCommonInvoiceMsg").css("display", "block");
		} else {
			if (selectedRadioElementId) {
				contractNumber = selectedRadioElementId;
				$("table.invoices-table-" + selectedRadioElementId).css("display", "table");
				$("table.paid-invoices-table-" + selectedRadioElementId).css("display", "table");
				$("#ubetalt-headline").css("display", "block");
				$("#betalt-section").css("display", "block");

				var noUnpaidInvoices = (!$("table.invoices-table-" + selectedRadioElementId).length);
				var noPaidInvoices = (!$("table.paid-invoices-table-" + selectedRadioElementId).length);
				var hasUnpaidInvoices = !noUnpaidInvoices;
				var hasPaidInvoices = !noPaidInvoices;

				if (noUnpaidInvoices && noPaidInvoices) {
					$("#noPaidNoUnpaid").css("display", "block");
					$(".invoicesCategoryHeading").css("display", "none");
				} else if (noUnpaidInvoices && hasPaidInvoices) {
					$("#ubetalt-headline").css("display", "none");
				} else if (hasUnpaidInvoices && noPaidInvoices) {
					$("#betalt-section").css("display", "none");
					// $("#noPaidButUnpaid").css("display", "block");
				}
			} else {
				$("#noPaidNoUnpaid").css("display", "block");
				$(".invoicesCategoryHeading").css("display", "none");
			}
		}
	}

	$("input.properties-radio-button").on("click", function (e) {

		$(".no-invoices-warning").css("display", "none");
		$(".invoicesCategoryHeading").css("display", "block");

		$("#fakturasporsmalFormFields").removeClass("hidden");
		$("#fakturasporsmalSuccessMsg").addClass("hidden");
		$("#fakturasporsmalErrorMsg").addClass("hidden");
		$('#submittingForm').removeClass("loading");
		$("#fakturasporsmalForm [name=description]").val("");

		contractNumber = "";
		addressCode = "";

		$("table.invoices-table").css("display", "none");
		$("table.paid-invoices-table").css("display", "none");

		var elementId = $(this).attr("id");
		var dataAddressCode = $(this).attr("data-address-code");
		var dataPropertyNumber = $(this).attr("data-property-number");

		if (dataAddressCode) {
			addressCode = dataAddressCode;
		}

		if (dataPropertyNumber) {
			window.history.pushState(null, '', "?pn=" + dataPropertyNumber);
		}

		var invalidProperty = String($(this).attr("data-invalid-property")) == "true";

		if (invalidProperty) {
			$(".invoicesCategoryHeading").css("display", "none");
			$("#notCommonInvoiceMsg").css("display", "block");
		} else {
			if (elementId) {
				contractNumber = elementId;
				$("#ubetalt-headline").css("display", "block");
				$("#betalt-section").css("display", "block");
				$("table.invoices-table-" + elementId).css("display", "table");
				$("table.paid-invoices-table-" + elementId).css("display", "table");

				var noUnpaidInvoices = (!$("table.invoices-table-" + elementId).length);
				var noPaidInvoices = (!$("table.paid-invoices-table-" + elementId).length);
				var hasUnpaidInvoices = !noUnpaidInvoices;
				var hasPaidInvoices = !noPaidInvoices;

				if (noUnpaidInvoices && noPaidInvoices) {
					$("#noPaidNoUnpaid").css("display", "block");
					$(".invoicesCategoryHeading").css("display", "none");
				} else if (noUnpaidInvoices && hasPaidInvoices) {
					$("#ubetalt-headline").css("display", "none");
				} else if (hasUnpaidInvoices && noPaidInvoices) {
					$("#betalt-section").css("display", "none");
					// $("#noPaidButUnpaid").css("display", "block");
				}
			} else {
				$("#noPaidNoUnpaid").css("display", "block");
				$(".invoicesCategoryHeading").css("display", "none");
			}
		}

	});

	// ##############################################################################

	// ##### invoices details page ##################################################

	$('#fakturasporsmalForm').on('submit', function (e) {

		e.preventDefault();

		var formData = $(this).serialize();
		var serviceUrl = $(this).attr("action");

		if (!$("#fakturasporsmalForm input[name=contract]").length) {
			formData += "&contract=" + contractNumber;
		}

		if (!$("#fakturasporsmalForm input[name=addressCode]").length) {
			formData += "&addressCode=" + addressCode;
		}

		$.ajax({
			url: serviceUrl,
			method: 'POST',
			dataType: 'json',
			data: formData,
			beforeSend: function () {
				$('#fakturasporsmalForm input[type=submit]').after('<div id="submittingForm" class="loading"></div>');
			},
			success: function (data) {
				$("#fakturasporsmalFormFields").addClass("hidden");
				$("#fakturasporsmalSuccessMsg").removeClass("hidden");
			},
			error: function (xhr, status, error) {
				$("#fakturasporsmalFormFields").addClass("hidden");
				$("#fakturasporsmalErrorMsg").removeClass("hidden");
			}
		});

	});
	// ##############################################################################



	// ######### skip min side form (hopp over button) methods below ########
	checkCookieOnLoad()

	$('#skipFormMinSide').click(function (e) {
		setCookieMinSide('skipMinSideCookie', true, 30)
	})

	$('.idporten__link.idporten__link--logout.pull-right').click(function (e) {
		deleteCookie('skipMinSideCookie')
	})

	// using form id
	$('#endre-kontaktinformasjon').submit(function (e) {
		document.cookie = "skipMinSideCookie=; path=/ ; expires=Thu, 01 Jan 1970 00:00:01 GMT";
	})


	//Comport form
	$("input[name='hasCompost']").change(function () {
		if ($("input[name='hasCompost']:checked").val() != undefined) {
			$("#errorMessage").hide();
		}
	})

	$("#js-printOpeningHoursDesktop").click(function () {
		printOpeningHoursTable()
	});

});

function printOpeningHoursTable () {
	$('#openinghours').removeClass('table-wrapper desktop')

	for (var t = document.querySelectorAll("div:not(.show-when-printing)"), e = 0; e < t.length; e++) {
		"openinghours" !== t[e].id && null === t[e].querySelector("#openinghours") && t[e].classList.add("hide-when-printing");

	}
	window.print();
	$('#openinghours').addClass('table-wrapper desktop')
}

// I've defined the path to be the root one '/'
function setCookieMinSide (cname, cvalue, exdays, path) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + "; path=/ ;" + expires;
}

function deleteCookie (name, path, domain) {
	if (getCookie(name)) {
		document.cookie = name + "=" +
			((path) ? ";path=" + path : "") +
			((domain) ? ";domain=" + domain : "") +
			";expires=Thu, 01 Jan 1970 00:00:01 GMT";
	}
}


function checkCookieOnLoad () {
	if (getCookie("skipMinSideCookie")) {
		// console.log('skipMinSideCookie enabled')
	} else {
		// console.log('skipMinSideCookie disabled')
	}
}
