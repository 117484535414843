$(document).ready(function() {
    function searchFn(event) {
        var isWordCharacter = event && event.key ? event.key.length === 1 : true;
        var isBackspaceOrDelete = event
            ? event.keyCode == 8 || event.keyCode == 46
            : true;
        var searchTerm = $(this).val() || "";
        var searchResultsId = "#searchResults";
        if ($(this).attr("id") == "searchPageInput") {
            searchResultsId = "#searchPageResults";
        }

        if ((isWordCharacter || isBackspaceOrDelete) && searchTerm.length >= 3) {
            if (searchResultsId == "#searchPageResults") {
                $("#searchTerm").html(searchTerm);
            }
            $.ajax({
                url:
                "/_/service/com.enonic.app.rfd/searchService?searchTerm=" +
                searchTerm,
                type: "POST",
                success: function(data) {
                    $(searchResultsId).html("");
                    if (data.results && data.results.length > 0) {
                        $(searchResultsId).show();
                        data.results.forEach(function(result, index) {
                            if (searchResultsId == "#searchResults") {
                                var buttonClasses =
                                    "autocompletelist__item autocompletelist__item--" +
                                    (index == 0);
                                $(searchResultsId).append(
                                    "<button class='" +
                                    buttonClasses +
                                    "' data-href='" +
                                    result.url +
                                    "' >" +
                                    result.title +
                                    "</button>"
                                );
                            } else {
                                $(searchResultsId).append(
                                    "<li class='navigationlist__item'><a class='navigationlist__link' href='" +
                                    result.url +
                                    "' >" +
                                    result.title +
                                    "</a></li>"
                                );
                            }
                        });
                    } else {
                        //$(searchResultsId).hide();
                        //$(searchResultsId).html("No results.");
                    }
                },
                error: function(xhr) {
                    console.log(xhr.responseText);
                }
            });
        } else {
            //$(searchResultsId).hide();
            //$(searchResultsId).html("");
        }
    }


    $(".autocompletelist").on(
        "click",
        ".autocompletelist__item",
        function() {
            if ($(this).data("href")) {
                // console.log($(this).data("href"));
                window.location = $(this).data("href");
            }
        }
    );


    $("#searchInput").keyup(searchFn);
    $("#searchPageInput").keyup(searchFn);

    $("#searchInput").focus(function() {
        $(this).removeClass("search__input--icon");
        $("#searchResults").show();
    });

    $("#searchInput").blur(function() {
        setTimeout(function() {
            $(this).addClass("search__input--icon");
            $("#searchResults").hide();
            //$("#searchResults").html("");
        }, 500);
    });


    $(".rfd-menu").click(function(t) {
        $(".rfd-menu").toggleClass("is-open");
        $(".nav").toggleClass("visible-md-block visible-sm-block visible-xs-block");
        t.preventDefault();
    });

    function hideSearchMenu() {
        if ($(window).width() < 1200) {
            $("#searchInput").click(function() {
                $("nav.nav>.menu>.menu__item").hide();
                $("nav.nav>.menu>.menu__item:last-child").show();
                $("body, html").animate({
                    scrollTop: $("#searchInput").position().top
                });
            });
        } else {
            // $("#searchInput").unbind("click");
        }
    }

    hideSearchMenu();
    $(window).resize(hideSearchMenu);

    $("#searchInput").focusout(function() {
        setTimeout(function(){
            $("nav.nav>.menu>.menu__item").show();
        }, 500)
    });
});
