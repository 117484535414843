/*jQuery(function($) {
	$( "#select2-select-place" ).change(function() {
		$( ".open__time" ).addClass( "hidden" );

		$( this ).find( "option:selected" ).each(function() {
			var filter = $( this ).val();

			$( ".open__time" ).each(function() {
				if( $(this).data( "filter" ) == filter ) {
					$(this).removeClass( "hidden" );
				}
			});
		});
	});
});*/

function getTimeRemaining (starttime, endtime) {
	var t = Date.parse(endtime) - Date.parse(starttime);
	var seconds = Math.floor((t / 1000) % 60);
	var minutes = Math.floor((t / 1000 / 60) % 60);
	var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
	var days = Math.floor(t / (1000 * 60 * 60 * 24));
	return {
		'total': t,
		'days': days,
		'hours': hours,
		'minutes': minutes,
		'seconds': seconds
	};
}


/* cookies functions*/
function setCookie (cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}

function getCookie (cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function cookieResponseclear () {
	setCookie('favoritesTours', "");
	return "";
}

function initializeClock (el, starttime, endtime) {

	var clock = $(el).find(".remain__time");

	function updateClock () {
		var timeString = "";
		var t = getTimeRemaining(starttime, endtime);
		starttime = new Date(starttime.valueOf() + 60000);
		// t.hours ? timeString = t.hours + 't '  : "";
		// clock.html( timeString + t.minutes + 'm' );
		t.hours ? timeString = t.hours + (t.hours > 1 ? ' timer ' : ' time ') : "";
		clock.html(timeString + t.minutes + (t.minutes > 1 ? ' minutter' : ' minutt'));
		if (t.total <= 0) {
			clearInterval(timeinterval);

			$(el).find(".open__today").show();
			$(el).find(".open__period").show();
			$(el).find(".open__on").hide();
			$(el).find(".remain__time").hide();
			$(el).find(".closed__today").hide();

			clock.html('Stengt');
		}
	}
	updateClock();
	var timeinterval = setInterval(updateClock, 60000);
}

$(document).ready(function () {

	function setCookie (cname, cvalue, exdays) {

		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + "; " + expires;
	}

	function getCookie (cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	$("#select-bags").change(function () {
		$(".open__time").addClass("hidden");

		$(this).find("option:selected").each(function () {
			var filter = $(this).val();

			setCookie('station', $(this).val(), 60)

			$(".open__time").each(function () {
				if ($(this).data("filter") == filter) {
					$(this).removeClass("hidden");
				}
			});

		});
	});

	// Schema auto trigger
	if ($("#select-lifecycle").length) {
		$("#select-lifecycle").trigger("change");
	}

	// Place open time auto trigger
	//if ( $( "#select2-select-place" ).length ) {
	//	$( "#select2-select-place" ).find('option:eq(0)').attr('selected', 'selected');
	//	$( "#select2-select-place" ).trigger( "change" );
	//}


	// Place open time auto trigger
	if ($("#select-bags").length) {

		if (/chrome|firefox|edge|msie/i.test(navigator.userAgent.toLowerCase())) {
			$("#select-bags").find('option:selected').removeAttr("selected");

		} else {

			//check if is not IE11
			if (navigator.appVersion.indexOf('Trident/') == -1) {
				$("#select-bags").val([]);
			}
		}

		if (document.cookie) {
			var station = getCookie('station');

			if (station) {
				$("#select-bags").find('option[value="' + station + '"]').attr('selected', 'selected');
			} else {
				$("#select-bags").find('option[value="lindum-drammen"]').attr('selected', 'selected');
			}
		} else {
			$("#select-bags").find('option[value="lindum-drammen"]').attr('selected', 'selected');
		}

		$("#select-bags").trigger("change");
	}

	// Open time countdown
	if ($(".recycling-infobox__wrap").length) {
		var now = new Date(moment().tz("Europe/Oslo").format().split("+")[0]);

		$(".open__time").each(function () {
			var closeTime = $(this).find(".close__time").text();

			if (closeTime != "") {
				var close = new Date(moment.tz(moment().format("YYYY-MM-DD") + " " + closeTime, "Europe/Oslo").format().split("+")[0]);
				initializeClock($(this), now, close);
			}
		});
	}

	//if($('body').find('#wasteForm')){ // verify if form exist

	//	if ($('#wastePart').length > 0) { // verify if {{WASTE}} exist

	//		jQuery("#wasteForm").detach().appendTo("#wastePart"); // move to div waste

	//	}
	//}

	$("[data-invoice]").on("click", function () {
		var invoiceNumber = $(this).data("invoice");
		setCookie("invoice", invoiceNumber)
	})

	$(".js-datepicker-exception-from").datepicker({
		minDate: "-6M", //six months in the past
		maxDate: "2Y6M" //six months
	});

	// $(".js-datepicker-exception-to").datepicker({
	//     disabled: true
	// });
	$(".js-datepicker-exception-to").prop('readonly', true);


	$(".js-datepicker-exception-from").on("change", function (e) {
		var minDate = new Date(moment($(".js-datepicker-exception-from").datepicker("getDate")).add(6, 'months').add(1, 'days').format("YYYY-MM-DD"));
		var maxDate = new Date(moment($(".js-datepicker-exception-from").datepicker("getDate")).add(3, 'years').add(1, 'days').format("YYYY-MM-DD"));

		$(".js-datepicker-exception-to").prop('readonly', false);
		$(".js-datepicker-exception-to").mask('00.00.0000');
		// $( ".js-datepicker-exception-to" ).datepicker( "option", "disabled", false );
		// $( ".js-datepicker-exception-to" ).datepicker( "option", "maxDate", maxDate );
		// $( ".js-datepicker-exception-to" ).datepicker( "option", "minDate", minDate );

	});

	$(".js-datepicker-exception-to").on("change", function (e) {
		var maxDate = moment($(".js-datepicker-exception-from").datepicker("getDate")).add(3, 'years');
		var toDate = moment($(".js-datepicker-exception-to").val().replace(/\./g, '-'), "DD-MM-YYYY");

		if (toDate.isValid() && toDate.diff(maxDate, "d") > 0) {
			if ($(".js-datepicker-exception-to-msg").hasClass('hidden')) {
				$(".js-datepicker-exception-to-msg").fadeIn(800).removeAttr('style');
				$(".js-datepicker-exception-to-msg").removeClass('hidden');
				$('#submitFormException').prop('disabled', true);
				$('#submitFormException').addClass('submitFormExceptionOpacity');
			}
		} else {
			if (!$(".js-datepicker-exception-to-msg").hasClass('hidden')) {
				$(".js-datepicker-exception-to-msg").fadeOut(800).removeAttr('style');
				$(".js-datepicker-exception-to-msg").addClass('hidden');
				if ($('#readConfirmation').prop('checked')) {
					$('#submitFormException').prop('disabled', false);
					$('#submitFormException').removeClass('submitFormExceptionOpacity');
				}
			}
		}
	});

	$(".form__label--radio-my-container").on("click", function (e) {
		var invalidProperty = $(this).find("input").data("invalid-property");
		if (invalidProperty)
			$("#enableSubmit").addClass("hidden");
	});


	$('#submitFormException, #submitFormRenovationFee, #submitFormMeldRenovationFee, #submitFormOrderAccess, #submitFormContainersSigdal, #submitFormDeclaration').prop('disabled', true)
	$('#submitFormException, #submitFormRenovationFee, #submitFormMeldRenovationFee, #submitFormOrderAccess, #submitFormContainersSigdal, #submitFormDeclaration').addClass('submitFormExceptionOpacity');
	$("#readConfirmation").on('change', function (e) {
		var maxDate = moment($(".js-datepicker-exception-from").datepicker("getDate")).add(3, 'years');
		var toDate = moment($(".js-datepicker-exception-to").val().replace(/\./g, '-'), "DD-MM-YYYY");

		if (!(toDate.isValid() && toDate.diff(maxDate, "d") > 0) && this.checked) {
			$('#submitFormException').prop('disabled', false)
			$('#submitFormException').removeClass('submitFormExceptionOpacity');
		} else {
			$('#submitFormException').prop('disabled', true)
			$('#submitFormException').addClass('submitFormExceptionOpacity');
		}
	})

	$("#readConfirmationRenovation, #readConfirmationOrderAccess, #readConfirmationSigdalForm, #readConfirmationDeclaration").on('change', function (e) {

		if (this.checked) {
			$('#submitFormRenovationFee, #submitFormMeldRenovationFee, #submitFormOrderAccess, #submitFormContainersSigdal, #submitFormDeclaration').prop('disabled', false)
			$('#submitFormRenovationFee, #submitFormMeldRenovationFee, #submitFormOrderAccess, #submitFormContainersSigdal, #submitFormDeclaration').removeClass('submitFormExceptionOpacity');
		} else {
			$('#submitFormRenovationFee, #submitFormMeldRenovationFee, #submitFormOrderAccess, #submitFormContainersSigdal, #submitFormDeclaration').prop('disabled', true)
			$('#submitFormRenovationFee, #submitFormMeldRenovationFee, #submitFormOrderAccess, #submitFormContainersSigdal, #submitFormDeclaration').addClass('submitFormExceptionOpacity');
		}
	})


	$('#bolig-accomodation').prop('checked', true)
	$("input[name='application']").on('change', function (e) {
		const isClicked = $('#bolig-accomodation').prop('checked')
		if (isClicked) {
			$("input[name='applicationSub']").parent().css('display', 'block')
			// $('#boenhetenFirst').prop('checked', true)
			$("input[name='applicationSub']").prop('checked', false)
		} else {
			$("input[name='applicationSub']").parent().css('display', 'none')
		}

		const textAreaOptionClicked = $('#other').prop('checked')
		if (textAreaOptionClicked) {
			$('#application').css('display', 'block')
		} else {
			$('#application').css('display', 'none')
		}
	})

	$("input[name='_property']").on('change', function (e) { // checks for bolig or Fritidsbolig/hytte
		const currentValue = $(this).val()
		$('#application').css('display', 'none') // on change, we hide the specifier text area

		if (currentValue === 'Bolig') {
			// $('#boenhetenFirst').prop('checked', true)
			$("input[name='applicationSub']").prop('checked', false)
			$("input[name='applicationSub']").parent().css('display', 'block')
			if ($("input[name='receipt_0']").attr('required')) {
				$("input[name='receipt_0']").removeAttr('required')
			}
		} else {
			$("input[name='applicationSub']").parent().css('display', 'none')
			$("input[name='receipt_0']").attr('required', '')
		}
	})

	shareRequest();

	formSuggestionSubmit();

});

function shareRequest () {
	$(".confirm-share, .deny-share").on("click", function () {
		var input = this
		var id = $(this).data().form
		var propertyNumber = $(this).data().property
		var accept = $(this).data().accept

		var formContent = {
			data: {
				id: id,
				propertyNumber: propertyNumber,
				accept: accept
			}
		}

		$(".share-container-alert .introduction .loading").remove();

		$.ajax({
			url: "_/service/com.enonic.app.rfd/shareContainer",
			type: "POST",
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify(formContent),
			beforeSend: function () {
				$(".share-container-alert .introduction").append('<div class="loading"></div>')
			},
			success: function (data) {
				$(input).parents(".share-container-alert").hide();
				$(".share-container-alert .introduction .loading").remove();
			},
			error: function (xhr) {
				$(".share-container-alert .introduction .loading").remove();
			}
		});

	})

}

function formSuggestionSubmit () {
	$('#form-suggestion').on('submit', function (e) {
		e.preventDefault();

		try {
			// Convert form into plain object
			var formObj = $(this).serializeArray().reduce(function (obj, item, index) {
				if (obj[item.name]) {
					if (!Array.isArray(obj[item.name])) {
						// Convert it to array since there's already a value with this name
						obj[item.name] = [obj[item.name]];
					}
					obj[item.name].push(item.value);
				} else {
					obj[item.name] = item.value;
				}
				return obj;
			}, {});

		} catch (e) {
			console.error(e);
		}

		var form = $(this);

		// Accept terms verification for the suggestion form
		if (form.attr("id") == "form-suggestion") {
			var accept = form.find("input[name='accept']").prop('checked');
			if (!accept) {
				form.find("#accept").css("color", "#ce4703");
				form.find("#accept .form__checkbox").css("border-color", "#ce4703");
				return false;
			} else {
				form.find("#accept").css("color", "");
				form.find("#accept .form__checkbox").css("border-color", "");
			}
		}

		var formData = new FormData(form[0]);
		// Clear previous errors
		$("#errorMessage").hide();

		// Throw the form values to the server!
		$.ajax({
			type: form.attr('method'),
			url: form.attr('action'),
			data: formData,
			async: true,
			beforeSend: function () {
				$('#form-suggestion .btn-container.hideField').after('<div class="loading"></div>');
			},
			success: function (data) {

				if (data.status == 'fail') {
					if (data.msg) {
						$("#errorMessage").html(data.msg);
					}

					$(".hideField").hide();
					$("#errorMessage").show();

				} else if (data.status == 'success') {

					if ($("#confirmationPage").length) {

						window.location.href = $("#confirmationPage").val();
					} else {

						if (data.msg) {

							$("#successMessage").html(data.msg);
						}

						$("#errorMessage").hide();
						$("#successMessage").show();
						$(".hideField").hide();
					}
				}
			},
			error: function (jqXHR) {
				var erroMsg = jqXHR.responseJSON || jqXHR.responseText;
				$("#errorMessage").html(erroMsg.message);
				$(".hideField").hide();
				$("#errorMessage").show();
			},
			cache: false,
			contentType: false,
			processData: false
		});

		return false;
	});
}
